<template>
  <div class="UserHome">
    <div class="bottomDiv1" style="height: auto; margin-bottom: 15px;" >
      <div class="index_title" style="border-bottom: none">用户编辑</div>
      <div>
        <el-form label-position="left" style="width: 76vw; display: flex; justify-content: space-between;">
              <el-form-item style="width:50vw; margin-left: 5vw;">
                <el-input
                  v-model="FormData.phone"
                  placeholder="请输入用户手机号"
                ></el-input>
              </el-form-item>
         
              <div class="BtnSearch" style="width: 18vw; height: 30px;" @click="searchBtn()">查询</div>
        </el-form>
      </div>
      <!-- 表格部分 -->
      <div class="tableDiv">
        <el-table
          stripe="true"
          ref="multipleTableRef"
          :data="tableData"
          border
          v-loading="loading"
          height="500px"
          style="
            width: 90%; 
            margin: 20px auto;
            overflow-y: auto;
          "
        >
          <el-table-column label="用户编号" prop="user_number" />
          <el-table-column label="用户手机号" prop="phone" />
          <!-- <el-table-column label="用户状态" prop="is_active">
            <template #default="scope">
              <span v-if="scope.row.is_active === true">启用</span>
              <span v-if="scope.row.is_active === false">暂停</span>
            </template>
          </el-table-column> -->
          <el-table-column label="所在省" prop="province" />
          <el-table-column label="所在市" prop="city"/>
          <el-table-column label="所在区县" prop="county"/>
          <!-- <el-table-column label="邮箱" prop="email" /> -->
          <!-- <el-table-column label="电动车余额" prop="balance" />
          <el-table-column label="电动汽车余额" prop="balance_new" /> -->
          <!-- <el-table-column label="所属运营商" prop="username" /> -->
          <el-table-column
            label="操作"
            align="center"
            width="140"
            fixed="right"
          >
            <template #default="scope">
              <div
                class="BtnSearch BtnSearch2"
                @click="handleEdit(scope.$index, scope.row)"
                >编辑</div
              >
              <!-- <div
                class="BtnSearch BtnSearch2"
                @click="handlePass(scope.$index, scope.row)"
                >添加密码</div
              > -->
              <!-- <div
                class="BtnSearch BtnSearch2" style="width:60px;"
                @click="userLogout(scope.$index, scope.row)"
                >注销</div
              > -->
            </template>
          </el-table-column>
        </el-table>
        
        <el-dialog
          v-model="Dialog.editLevy"
          title="编辑用户"
          width="84vw"
          
          :before-close="closeDialog"
          class="dialog"
        >
          <div class="formDiv1">
            <el-form label-position="right" label-width="100px">
              
                  <el-form-item class="item1" label="用户编号:">
                    <el-input
                      :disabled = "true"
                      v-model="Dialog.DialogData.user_number"
                    ></el-input>
                  </el-form-item>
              
                  <el-form-item class="item1" label="手机号:">
                    <el-input
                      v-model="Dialog.DialogData.phone"
                    ></el-input>
                  </el-form-item>
            
                  <el-form-item class="item1" label="选择省份:">
                    <el-select v-model="Dialog.DialogData.province" placeholder="请选择省" @change="handleProvinceChange">
                    <el-option v-for="provinces in provinceList" :key="provinces.value" :value="provinces.label" :label="provinces.label"/>
                    <!-- </el-option> -->
                  </el-select>
                  </el-form-item>
              
                  <el-form-item class="item1" label="选择市:">
                    <el-select v-model="Dialog.DialogData.city" placeholder="请选择市"  @change="handleCityChange">
                    <!-- <el-option value="">请选择</el-option> -->
                    <el-option v-for="citys in cityList[0]" :key="citys.value" :value="citys.label">{{ citys.label }}</el-option>
                  </el-select>
                  </el-form-item>
             
                  <el-form-item label="选择区/县:">
                    <el-select v-model="Dialog.DialogData.county" placeholder="请选择区/县" >
                    <!-- <el-option value="">请选择</el-option> -->
                    <el-option v-for="countys in countyList[0]" :key="countys.value" :value="countys.label">{{ countys.label }}</el-option>
                  </el-select>
                  </el-form-item>
            </el-form>
          </div>
          <template #footer>
            <span class="dialog-footer">
              <el-button class="Btn" @click="closeDialog">取消</el-button>
              <el-button class="Btn" @click="submitDialog">提交</el-button>
            </span>
          </template>
        </el-dialog>
      </div>
    </div>

 
 
    <!-- </div> -->
  </div>
</template>
<script>
import { onMounted, reactive, toRefs } from "vue-demi";
import { sel_user,upd_user,operator_data } from "@/request/api";
import { ElMessage } from "element-plus";
import {  regionData } from 'element-china-area-data'


// import ProvinceCityCountry from '../../assets/json/address'

export default {
  name: "SearchMan",
  setup() {
    const data = reactive({
      //表单数据
      FormData: {
       
      },
      isadmin:false,
      users:[],
      tableData: [
      ],
      loading: false,

      options: regionData,
      // 省市县
      regionData,
      provinceList: [],
      cityList: [],
      countyList: [],
      province: '',
      city: '',
      county: '',
          
       //   弹框
       Dialog: {
        editLevy: false,
        DialogData: {},
      },
      

    });

    
    const searchBtn = () => {
      getList();
    };
    
    const handleEdit = (index, row) => {
      console.log(row,'--=');
      data.Dialog.editLevy = true;
      data.Dialog.DialogData = row;
    };





    const initProvinceList =()=> {
      
      // 初始化省份列表数据源    
      regionData.forEach((tab) => {
            data.provinceList.push(tab)
          });
          console.log(data.provinceList,'--**99')

    }
    const handleProvinceChange = (val) =>{
      console.log(val,'009-');
      data.cityList=[]
      data.countyList=[]
      data.province = val
       console.log(data.province,']]');
       initCityList()
       
    }

    
    const initCityList=()=> {
      console.log(data.province,'--2')
      // 初始化城市列表数据源
      data.provinceList.forEach((tab1)=>{
        // console.log(tab1,'--==tab1');
        if(tab1.label==data.province){
          data.cityList.push(tab1.children)
        }
      })
      console.log(data.cityList,'pppppp');
  }

  const handleCityChange=(value1)=>{
    data.countyList=[]
    data.city = value1
       console.log(data.city,']]999');
       initCountyList()
    }

    const initCountyList=()=> {
      // 初始化县/区列表数据源
      console.log(data.city,'--299')
      // 初始化城市列表数据源
      data.cityList[0].forEach((tab2)=>{
        console.log(tab2,'tab2');
        // tab2.forEach((tab3)=>{
        //   console.log(tab3,'tab3');
        //    if(tab3.label==this.selectedCity){
        //  this.countyList.push(tab3.children)
        // }
        // })
        if(tab2.label==data.city){
          data.countyList.push(tab2.children)
        }
      })
      console.log(data.countyList,'pppppp999');
    }






    
    const closeDialog = () => {
      data.Dialog.editLevy = false;
    };
    const submitDialog = () => {
     
       upd_user(data.Dialog.DialogData).then((res) => {
        console.log(res);
        if (res) {
          closeDialog()
          if (res.code == 200) {
            ElMessage({
              showClose: true,
              message: "编辑成功",
              type: "success",
            });
            getList()
          } else {
            ElMessage.error(res.msg);
          }
        } else {
          ElMessage.error("编辑失败");
        }
      });
    };
    
    
   
    const getList = () => {
      const dataa = {
        operator_id : sessionStorage.getItem('userID'),
        username : data.FormData.username,
        province:data.FormData.province,
        city:data.FormData.city,
        county:data.FormData.county,
        user_number:data.FormData.user_number,
        phone:data.FormData.phone
      }
      console.log(dataa,'data.FormData11');
   
      data.loading = true;
      sel_user(dataa).then((res) => {
        console.log(res);
        if (res) {
          data.loading = false;
          if (res.code == 200) {
            data.tableData = res.data;
            data.total = res.data.length;
          } else {
            ElMessage.error(res.msg);
          }
        } else {
          ElMessage.error("数据获取失败");
        }
      });
    };
    const getUsername = () => {
        const username1 = localStorage.getItem('username')
        const users1 = []
        operator_data().then((res) => {
          console.log(res,'sssssss');
          data.users = res
          res.forEach((a)=>{    
            users1.push(a.username)
        })
        if(users1.indexOf(username1)==-1){
          data.isadmin = true
        }else{
          data.isadmin = false
          data.FormData.username = username1
        }
        });
      };
    const handleSizeChange = (val)=>{
        data.pageSize = val
    }
    const handleCurrentChange = (val)=>{
        data.currentPage = val
        // 
    }
    onMounted(() => {
      console.log(data);
      getList();
      getUsername();
      initProvinceList();
   
    });
    return {
      ...toRefs(data),
      searchBtn,
      getList,
      handleEdit,
      // handleChange1,
      initProvinceList,
      handleProvinceChange,
      handleCityChange,
      initCityList,
      initCountyList,
      // userLogout,
      closeDialog,
      submitDialog,
      handleSizeChange,
      handleCurrentChange,
      getUsername
    };
  },
};
</script>

<style>
.index_title {
  font-size: 18px;
  color: #333;
  border-left: 5px solid #04a0c3;
  padding-left: 25px;
  margin-bottom: 15px;
}
.bottomDiv1 {
  background-color: #fff;
  padding: 0.5rem;
  font-size: 18px;
}
.pagination{
    display: flex;
    justify-content: space-between;
    padding: 0 67px;
    margin-top: -10px;
}
.formDiv1{
  width: 70vw;
}
.item1{
  margin-bottom: 5px !important;
  margin-left: -1vw;
}
.bottomDiv {
    min-height: 180px;
  }
 
  .s_title {
    font-size: 14px;
  }
  .s_title .el-icon {
    float: right;
    color: red;
  }
  .p_bottom .el-icon {
    float: right;
    color: green;
  }
  .s_water {
    line-height: 40px;
    font-size: 19px;
    /* font-weight: bold; */
  }
  .s_profress {
    line-height: 25px;
  }
 

.BtnSearch2{
  float: left;
  width: 100px;
  margin: 0 4px;
}
</style>







